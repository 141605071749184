import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  FormLabel,
  Checkbox,
  IconButton,
  Grid,
  Chip,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import Phone from "../../assets/images/phone.svg";
import Message from "../../assets/images/message.svg";
import Edit from "../../assets/images/icon_edit.svg";
import filterBtn from "../../assets/images/filterButtons.png";
import Add from "../../assets/images/add.svg";
import Calender from "../../assets/images/calender.svg";
import people from "../../assets/images/people.svg";
import table from "../../assets/images/dining-table.svg";
import calendarR from "../../assets/images/calendarRArw.svg";
import calendarD from "../../assets/images/calendar.svg";
import calendarL from "../../assets/images/calendarLArw.svg";
import rupee from "../../assets/images/rupee.svg";
import Glass from "../../assets/images/glass.svg";
import Style from "./styles/CustomerList.module.css";
import { toast } from "react-toastify";
import {
  convertNumbers,
  convertSimpleDateFormat,
  convertToAMPM,
  formatAMPM,
  formatDatetime,
  addMinutesToTime,
  formatISODateString,
  formatTableNumber,
  maskNumber,
} from "../../utils";
import { IMAGE_BASE_URL } from "../../config/config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreviousScreen,
  setSelectedContacts,
  setSelectedIds,
  setSelectedReservations,
  setRestaurantsList
} from "../../redux/slices/masterDataSlice";
import { useAuth } from "../../hooks/useAuth";
import { ENDPOINT, TOAST_CONFIG, USER_TYPE } from "../../config/constants";
import { Encrypt } from "../../Crypto-Helper";
import { Label } from "@mui/icons-material";
import { POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import BookingDetailPopup from "../../components/live-table/BookingDetailPopup";
import ChangeTablePopup from "../../components/live-table/ChangeTablePopup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Style1 from "../liveTable/styles/LiveTable.module.css";
import {
  InputAdornment,
  TextField,
  Button,
  MenuItem,
  Divider,
  Container,
} from "@mui/material";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import TimeSelector from "../../components/time-selector/TimeSelector";


function CustomerList({
  data,
  dashboardActions,
  component,
  showCheckBox,
  birthday,
  anniversary,
  filtered,
  reservationFilter,
  reservation,
  checkInOut,
  reservationExport,
  restaurantsList,
  getData
}) {

  // console.log("reservation", reservation);
  // console.log("reservationExport",reservationExport);
  // console.log("filtered",filtered);
  // console.log("data", data);
  // console.log("reservationFilter",reservationFilter); // this one 


  const { role, logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedContacts,
    selectedIds,
    selectedReservationIds
    // restaurantsList,
  } = useSelector((state) => state.masterData);
  const [isArrived, setIsArrived] = useState(data?.isArrived ?? false);
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState(null);

  const [bookedTbl, setBookedTbl] = useState(reservation ? data : null);
  const [showBookingDetailsPopup, setShowBookingDetailsPopup] = useState(false);
  const [restId, setRestId] = useState(null);
  const [restaurantData, setRestaurantData] = useState(null);
  const [restSpending, setRestSpending] = useState([]);
  const [showChangeTablePopup, setShowChangeTablePopup] = useState(false);
  const [layout, setLayout] = useState(null);
  const [date, setDate] = useState(dayjs(new Date()));
  const [currentTime, setCurrentTime] = useState({
    hh: formatAMPM(new Date())?.hours,
    mm: formatAMPM(new Date())?.minutes,
    meridiem: formatAMPM(new Date())?.ampm,
  });
  const [dateError, setDateError] = useState("");
  const [refresh, setRefresh] = useState(0);
  const hasFetched = useRef(false);

  // Check if the URL contains the keyword "export"
  const isExportRoute = location.pathname.includes('export');

  // useEffect(() => {
  //   getAllRestaurants();
  // }, [refresh, currentTime, restId, date]);

  const fetchData = useCallback(() => {
    if (!hasFetched.current) {
      getAllRestaurants();
      hasFetched.current = true;
    }
  }, [currentTime, restId, date]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (refresh && !hasFetched.current) {
        fetchData();
      }
    }, 2000); // Adjust debounce delay as needed (in milliseconds)

    return () => clearTimeout(debounceTimeout);
  }, [refresh, fetchData]);

  const getAllRestaurants = async () => {
    try {
      const payload = new FormData();
      payload.append(
        "fromTime",
        `${currentTime.hh}:${currentTime.mm}:00 ${currentTime.meridiem}`
      );
      payload.append(
        "toTime",
        addMinutesToTime(
          `${currentTime.hh}:${currentTime.mm}:00 ${currentTime.meridiem}`,
          restaurantData?.occupancy_time
            ? parseInt(restaurantData?.occupancy_time)
            : 120
        )
      );
      payload.append(
        "bookingDate",
        formatISODateString(new Date(date).toISOString())
      );

      const resp = await POST_MULTIPART(
        ENDPOINT.GET_ALL_RESTAURANTS_LIVE_TABLES,
        // ENDPOINT.GET_ALL_RESERVATION_BY_DATE_TIME,
        payload
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const handleReset = () => {
    setCurrentTime({
      hh: formatAMPM(new Date())?.hours,
      mm: formatAMPM(new Date())?.minutes,
      meridiem: formatAMPM(new Date())?.ampm,
    });
    setDate(dayjs(new Date()));
    setDateError("");
  };

  const slotProps = {
    textField: {
      placeholder: "Date",
      variant: "standard",
      sx: {
        width: "100%",
        "& .MuiInput-underline:before": {
          borderBottom: "2px solid #D9D9D9",
        },
        // marginRight: "30px",
        marginBottom: "20px",
      },
      inputProps: {
        fontSize: "16px",
        fontWeight: "400",
        marginBottom: "5px",
      },
    },
    actionBar: {
      actions: ["accept"],
    },
  };

  useEffect(() => {
    setDate(dayjs(bookedTbl?.booking_date));
    setCurrentTime(bookedTbl ? {
      hh: bookedTbl && convertToAMPM(bookedTbl?.booking_time, true)[0],
      mm: bookedTbl && convertToAMPM(bookedTbl?.booking_time, true)[1],
      meridiem: bookedTbl && convertToAMPM(bookedTbl?.booking_time, true)[2],
    } : {
      hh: formatAMPM(new Date())?.hours,
      mm: formatAMPM(new Date())?.minutes,
      meridiem: formatAMPM(new Date())?.ampm
    });

  }, [bookedTbl]);

  useEffect(() => {
    if (data?.restId) {
      setRestId(data?.restId);
      const rest = restaurantsList?.data?.data?.original?.find(
        (item) => item?.id === data?.restId
      );
      rest && setRestaurantData(rest);

    }
    const restIdx = restaurantsList?.data?.data?.original?.findIndex(
      (r) => r.id === data.restId
    );
    if (restIdx >= 0) {
      const spendingArr = [];
      for (let index = 1; index <= 4; index++) {
        spendingArr.push(
          restaurantsList?.data?.data?.original[restIdx][`spending_${index}`]
        );
      }
      setRestSpending(spendingArr);
    }

    if (restaurantsList?.data?.data?.original?.length) {
      const rest = restaurantsList?.data?.data?.original[0];
      setRestId((prev) => (prev ? prev : rest?.id));
      setRestaurantData((prev) => (prev ? prev : rest));
      setRestSpending((prev) =>
        prev
          ? prev
          : [
            rest?.spending_1,
            rest?.spending_2,
            rest?.spending_3,
            rest?.spending_4,
          ]
      );

      let prevRestIndex = null;
      if (restId)
        prevRestIndex = restaurantsList?.data?.data?.original?.findIndex(
          (item) => item?.id === restId
        );

      if (prevRestIndex && prevRestIndex >= 0) {
        setLayout(
          JSON.parse(
            restaurantsList?.data?.data?.original[prevRestIndex]?.restroLayout
          )
        );
      }
      else {
        setLayout(
          JSON.parse(restaurantsList?.data?.data?.original[0]?.restroLayout)
        );
      }
    }
  }, [restaurantsList]);

  useEffect(() => {
    if (data?.isArrived !== undefined) {
      setIsArrived(data?.isArrived);
    }
  }, [data]);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [data]);



  const handleRestaurantOptionPillClick = (
    newRestaurantOption,
    isChangeTable
  ) => {
    setShowBookingDetailsPopup(false)
    setRestId(newRestaurantOption.id);
    setRestaurantData(newRestaurantOption);
    setRestSpending([
      newRestaurantOption?.spending_1,
      newRestaurantOption?.spending_2,
      newRestaurantOption?.spending_3,
      newRestaurantOption?.spending_4,
    ]);
    setLayout(JSON.parse(newRestaurantOption?.restroLayout));
    !isChangeTable && setBookedTbl(null);
  };
  const handleMouseEnter = () => {
    if (isTruncated) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  const handleTouchStart = () => {
    if (isTruncated) {
      setTooltipOpen(true);
    }
  };

  const handleTouchEnd = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }

    // Keep tooltip open for a short duration
    const timeoutId = setTimeout(() => {
      setTooltipOpen(false);
    }, 2000); // Adjust duration as needed

    setTooltipTimeout(timeoutId);
  };

  const handleDateChange = (date) => {
    setDate(date);
    setDateError("");
  };

  const handleTimeChange = (e) => {
    // setCurrentTime(newTime);
    setCurrentTime((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChecked = (e, data) => {
    if (e.target.checked) {
      dispatch(setSelectedContacts([...selectedContacts, data.contactNo]));
      dispatch(setSelectedIds([...selectedIds, data.id]));
    } else {
      if (selectedContacts.indexOf(data.contactNo) >= 0) {
        const newList = selectedContacts.filter((no) => no !== data.contactNo);
        dispatch(setSelectedContacts(newList));
      }
      if (selectedIds.indexOf(data.id) >= 0) {
        const newList = selectedContacts.filter((_id) => _id !== data.id);
        dispatch(setSelectedIds(newList));
      }
    }
  };
  const onCheckedReservation = (e, id) => {
    if (e.target.checked) {
      dispatch(setSelectedReservations([...selectedReservationIds, id]));
    } else {
      if (selectedContacts.indexOf(data.contactNo) >= 0) {
        const newList = selectedReservationIds.filter((dt) => dt !== id);
        dispatch(setSelectedReservations(newList));
      }
      if (selectedReservationIds.indexOf(id) >= 0) {
        const newList = selectedReservationIds.filter((_id) => _id !== id);
        dispatch(setSelectedReservations(newList));
      }
    }
  };

  const checkIsSelected = (id) => {
    let result = false;
    if (reservationExport) {
      if (selectedReservationIds?.includes(id)) result = true;
    } else {
      if (selectedContacts?.includes(id)) result = true;
    }
    return result;
  };

  const handleSendMessage = (id) => {
    dispatch(setSelectedContacts([id]));
    navigate("/send-template");
  };

  const handleEditClick = () => {
    hasFetched.current = false;
    getAllRestaurants();
    setShowBookingDetailsPopup(true);
    setBookedTbl(data);
  };

  const handleCloseBookingPopup = () => {
    setShowBookingDetailsPopup(false);
    setBookedTbl(null);
    setRestSpending([]);
    setRefresh((prev) => prev + 1);
    getData()
    // getAllRestaurants();
  };

  const handleCloseChangeTablePopup = () => {
    handleCloseBookingPopup();
    setShowChangeTablePopup(false);
    setBookedTbl(null);
    setRestSpending([]);
    setRefresh((prev) => prev + 1);
    handleReset();
    getData()
    // getAllRestaurants();
  };


  const getRestaurentName = (restId) => {
    let name = "";
    let rest = restaurantsList?.data?.data?.original?.filter(
      (r) => r.id === restId
    );
    if (rest && rest?.length) {
      name = rest[0].name;
    }
    return name;
  };

  const handleIsArrived = async (e, data) => {
    setIsArrived(e.target.checked);
    const formData = new FormData();
    formData.append("reservationId", data?.reservationId);
    formData.append("arrived", e.target.checked ? 1 : 0);
    try {
      const response = await POST_MULTIPART(ENDPOINT.IS_ARRIVED, formData);
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };
  return (
    <div>
      <div
        className={`${!isExportRoute ? Style.mainWrapper : Style.adminRemarkWrapper} ${Style.mainWrapper}`}

      >
        <div className={Style.CustomerListWrapper}>
          {role === USER_TYPE.MANAGER && showCheckBox && (
            <Checkbox
              checked={checkIsSelected(
                reservationExport ? data?.reservationId : data?.contactNo
              )}
              onChange={(e) => {
                if (reservationExport)
                  onCheckedReservation(e, data?.reservationId);
                else onChecked(e, { id: data?.id, contactNo: data?.contactNo });
              }}
              className={Style.checkbox}
              sx={{ pr: 1 }}
            />
          )}
          <Link
            className={Style.CustomerInfoWrapper}
            to={
              reservationFilter
                ? `/history/${Encrypt(data.id)}`
                : // : reservation
                // ? `/reservation/${Encrypt(data.id)}`
                `/customer/edit/${Encrypt(data.id)}`
            }
          >
            <Avatar
              variant="square"
              src={
                data?.profileImage ? `${IMAGE_BASE_URL}${data?.profileImage}` : ""
              }
              className={Style.avatar}
              sx={{ borderRadius: "9px", width: "56.25px", height: "56.25px" }}
            />
            <div className={Style.customerDetail}>
              <FormLabel
                sx={{ cursor: "pointer" }}
                className={Style.customerNameLabel}
              >
                {data.name} {data.lastName}
              </FormLabel>
              {role === USER_TYPE.MANAGER ? (
                <p className={Style.phoneText}>{data?.contactNo}</p>
              ) : (
                <p className={Style.phoneText}>{maskNumber(data?.contactNo)}</p>
              )}
              {birthday ? (
                <p className={Style.dateText}>
                  {data?.dob ? formatDatetime(data?.dob) : ""}
                </p>
              ) : anniversary ? (
                <p className={Style.dateText}>
                  {data?.anniversaryDate
                    ? formatDatetime(data?.anniversaryDate)
                    : ""}
                </p>
              ) : (
                !checkInOut &&
                  !reservation &&
                  // data?.created_at && (

                  data?.created_at ? (
                  <p className={Style.dateText}>
                    {formatDatetime(data?.created_at)}
                  </p>
                ) :

                  data?.booking_date && (
                    <div className={Style.label}>
                      <img
                        className={Style.img}
                        src={calendarD}
                        alt="calendar_icon"
                      />
                      <p>{convertSimpleDateFormat(data?.booking_date)}</p>
                    </div>
                  )
              )}
              {data?.category?.length > 0 && component !== "whosHere" ? (
                <>
                  <div className={Style.initialWrapper}>
                    {data?.category?.map((cat, innerIndex) => (
                      <p className={Style.textField} key={innerIndex}>
                        {cat.alphabets}
                      </p>
                    ))}
                    {data?.isDnd ? (
                      <p className={`${Style.textField} ${Style.dnd} `}>X</p>
                    ) : null}
                  </div>
                </>
              ) : null}
              {/* reservation */}
              {/* {reservation && ( */}
              {(
                <div className={Style.labelWraper}>
                  {data?.booking_time ? (
                    <div className={Style.label}>
                      <img
                        className={Style.img}
                        src={calendarR}
                        alt="calendar_icon"
                      />
                      <p>{convertToAMPM(data?.booking_time)}</p>
                    </div>
                  ) : null}
                  {data?.booking_date && reservationExport ? (
                    <div className={Style.label}>
                      <img
                        className={Style.img}
                        src={calendarD}
                        alt="calendar_icon"
                      />
                      <p>{convertSimpleDateFormat(data?.booking_date)}</p>
                    </div>
                  ) : null}
                  {data?.peopleCount && <div className={Style.label}>
                    <img className={Style.img} src={people} alt="people_icon" />
                    <p>
                      {data?.peopleCount?.toString().length === 1
                        ? `0${data?.peopleCount}`
                        : data?.peopleCount}
                    </p>
                  </div>}
                  {(data?.tableNumberDetails || data?.pdrNumberDetails) && (
                    <div className={Style.label}>
                      <img
                        style={{ height: "20px" }}
                        className={Style.img}
                        src={table}
                        alt="table_icon"
                      />
                      <p>
                        {formatTableNumber(
                          data?.tableNumberDetails
                            ? data.tableNumberDetails +
                            (data?.pdrNumberDetails
                              ? `, ${data.pdrNumberDetails}`
                              : "")
                            : data?.pdrNumberDetails
                        )}
                      </p>
                    </div>
                  )}
                  {data?.spending && (
                    <div className={Style.label}>
                      <img className={Style.img} src={rupee} alt="rupee_icon" />
                      {data?.spending}
                    </div>
                  )}
                </div>
              )}

              {/* Check In / Out */}
              {checkInOut && (
                <div className={Style.labelWraper}>
                  {data?.check_in && (
                    <div className={Style.label}>
                      <img className={Style.img} src={calendarL} alt="message" />
                      {convertSimpleDateFormat(data?.check_in)}
                    </div>
                  )}
                  {data?.check_out && (
                    <div className={Style.label}>
                      <img className={Style.img} src={calendarR} alt="message" />
                      {convertSimpleDateFormat(data?.check_out)}
                    </div>
                  )}
                </div>
              )}
              <div className={Style.labelWraper}>

                {/* {reservation ? ( */}
                {
                  data?.restaurantName ? (
                    <div className={Style.label}>
                      <p>{data?.restaurantName}</p>
                    </div>
                  ) : data?.restId ? (
                    <div className={Style.label}>
                      <p>{getRestaurentName(data?.restId)}</p>


                    </div>

                  ) : null
                }

                {/* ) : null} */}


                {/* {data?.isArrived ? (
                <Chip
                  label="Arrived"
                  color="primary"
                  sx={{
                    height: "33px",
                    paddingX: "10px",
                  }}
                />
              ) : null} */}

              </div>
            </div>
          </Link>


          <div className={Style.iconWrapper}>
            {reservationFilter ? (
              <div>
                <div className={Style.iconBox}>
                  <img src={Calender} className={Style.iconImg} alt="calender" />
                  <p className={Style.iconText}>
                    {data?.checkinCount?.length
                      ? data?.checkinCount[0]?.checkinCount?.toString()
                        ?.length === 1
                        ? "0" + data?.checkinCount[0]?.checkinCount
                        : data?.checkinCount[0]?.checkinCount || 0
                      : 0}
                  </p>
                </div>
                <div className={Style.iconBox}>
                  <img src={Glass} className={Style.iconImg} alt="glass" />

                  <p className={Style.iconText}>
                    {data?.reservationCount?.length
                      ? data?.reservationCount[0]?.reservationCount?.toString()
                        ?.length === 1
                        ? "0" + data?.reservationCount[0]?.reservationCount
                        : data?.reservationCount[0]?.reservationCount || 0
                      : 0}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {!filtered && (
              <div className={Style.iconBoxBtnNew} >
                {reservation && (

                  <div className={Style.actionIcons}>
                    <IconButton onClick={handleEditClick}>
                      <img src={Edit} alt="edit" />
                    </IconButton>



                    <IconButton sx={{ p: 1 }}>
                      <Checkbox
                        checked={isArrived}
                        onChange={(e) => handleIsArrived(e, data)}
                        className={Style.checkbox}
                        sx={{ p: 0 }}
                      />
                    </IconButton>
                  </div>
                )}

                <IconButton
                  sx={{ p: 1 }}
                  onClick={() => {
                    window?.open(`tel:${data?.contactNo}`);
                  }}
                >
                  <img src={Phone} alt="phone" />
                </IconButton>
                {dashboardActions && role === USER_TYPE.MANAGER && (
                  <IconButton
                    sx={{ p: 1 }}
                    onClick={() => {
                      handleSendMessage(data?.contactNo);
                      dispatch(setPreviousScreen(location.pathname));
                    }}
                  >
                    <img src={Message} alt="message" />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        </div>



        {data?.category?.length > 0 && component === "whosHere" ? (
          <div className={Style.initialWrapper}>
            {data?.category?.map((cat, innerIndex) => (
              <p className={Style.textField} key={innerIndex}>
                {cat.alphabets}
              </p>
            ))}
          </div>
        ) : null}
      </div>

      <div
        className={`${role === USER_TYPE.MANAGER ? Style.shiftRightAdmin : ''} ${role === USER_TYPE.STAFF ? Style.remarkWrapper : ''}
${isExportRoute ? Style.remarkWrapperAdmin : ''} ${Style.remarkWrapper}`} >

        <Tooltip
          title={isTruncated ? data?.booking_remark : ''}
          placement="top"
          arrow
          open={tooltipOpen}
        >
          <Typography
            ref={textRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >

            {data?.booking_remark}

          </Typography>
        </Tooltip>
      </div>


      {showBookingDetailsPopup && (
        <BookingDetailPopup
          bookingDetails={bookedTbl}
          setBookedTbl={setBookedTbl}
          open={showBookingDetailsPopup}
          restId={restId}
          restaurant={restaurantData}
          handleClose={handleCloseBookingPopup}
          spendings={restSpending}
          handleChangeTablePopup={setShowChangeTablePopup}
        />
      )}

      {showChangeTablePopup && (
        <ChangeTablePopup
          bookingDetails={bookedTbl}
          layout={layout}
          open={showChangeTablePopup}
          restId={restId}
          handleClose={handleCloseChangeTablePopup}
          restaurantsList={restaurantsList}
          zoomState={0.8}
          handleRestaurantOptionPillClick={handleRestaurantOptionPillClick}
          slotProps={slotProps}
          date={date}
          time={currentTime}
          dateTimeSelector={
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={Style1.timeWrapper}>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Date
                  </p>
                  <MobileDatePicker
                    format="DD/MM/YYYY"
                    views={["year", "month", "day"]}
                    value={date}
                    // value={dayjs(bookedTbl?.booking_date)}
                    slotProps={slotProps}
                    error={dateError}
                    helperText={dateError}
                    onChange={(newValue) => handleDateChange(newValue)}
                    slots={{
                      textField: (props) => (
                        <TextField
                          {...props}
                          error={dateError}
                          helperText={dateError}
                        />
                      ),
                    }}
                  />
                </div>
              </LocalizationProvider>
              <div className={Style1.timeWrapper}>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Time
                </p>

                <TimeSelector
                  values={currentTime}
                  onChange={handleTimeChange}
                  removeDefault
                />
              </div>
              <Button sx={{ p: 0 }} size="small" onClick={handleReset}>
                Reset
              </Button>
            </>
          }
        />
      )}

    </div>

  );
}

export default CustomerList;
